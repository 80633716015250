import React, { useEffect } from "react";
import { useI18n } from "compass-commons";
import { Controller, useForm } from "react-hook-form";
import { TextField } from "dms-lib";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { zodResolver } from "@hookform/resolvers/zod";
import SiteSelector from "../sitesSelector/SiteSelector";
import { OnDemandIncidentFormDTO } from "../../../models/onDemandIncident/onDemandIncidentForm";
import FloorPlanSelector from "../floorPlanSelector/FloorPlanSelector";
import BehaviorSelector from "../behaviorSelector/BehaviorSelector";
import OperatorAssign from "../operatorAssign/OperatorAssign";
import OnDemandHeader from "../onDemandHeader/OnDemandHeader";
import useCurrentUser from "../../../hooks/useCurrentUser";

const OnDemandContainer = (): JSX.Element => {
  const { t: translate } = useI18n();

  const {
    control,
    watch,
    resetField,
    handleSubmit,
    reset,
    setError,
    setValue: setFieldValue,
    formState: { isSubmitting, isSubmitSuccessful, isDirty },
  } = useForm<OnDemandIncidentFormDTO>({
    mode: "all",
    resolver: zodResolver(OnDemandIncidentFormDTO),
    defaultValues: {
      site: null,
      floorplan: null,
      description: "",
      behavior: null,
      assign: {
        id: null,
        email: null,
        name: translate("ondemand.operatorAssignAutomaticOption"),
      },
    },
  });

  const selectedSite = watch(`site`)?.id || undefined;
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);
  return (
    <div
      className="ondemand-container"
      style={{ pointerEvents: isSubmitting ? "none" : "auto" }}
    >
      <OnDemandHeader
        watch={watch}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        isFormChanged={isDirty}
        setError={setError}
        currentUser={currentUser}
      />
      <div className="ondemand-container-main">
        <div className="ondemand-form">
          <div className="ondemand-form-section">
            <div className="ondemand-form-section-title">
              {translate("ondemand.sectionLocation")}
            </div>
            <SiteSelector
              control={control}
              setFieldValue={setFieldValue}
              resetField={resetField}
            />
            <FloorPlanSelector control={control} selectedSite={selectedSite} />
          </div>

          <div className="ondemand-form-section">
            <div className="ondemand-form-section-title">
              {translate("ondemand.sectionIncident")}
            </div>
            <BehaviorSelector control={control} selectedSite={selectedSite} />
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value, ...field } }) => {
                return (
                  <div>
                    <div className="ondemand-form-section-label">
                      {translate("ondemand.descriptionInputLabel")}
                    </div>
                    <div className="ondemand-form-section-textarea">
                      <TextField
                        {...field}
                        onChange={onChange}
                        value={value}
                        placeholder={translate(
                          "ondemand.descriptionInputPlaceholder"
                        )}
                        multiline
                        fullWidth
                        data-cr="incident-selector-description"
                        rows={4}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>

          <div className="ondemand-form-section">
            <div className="ondemand-form-section-title">
              {translate("ondemand.sectionHandling")}
            </div>
            <OperatorAssign
              control={control}
              setFieldValue={setFieldValue}
              watch={watch}
              currentUser={currentUser}
            />
          </div>
        </div>
        <div className="ondemand-container-main-map">
          <LocationOnIcon fontSize="large" />
          <span>{translate("ondemand.locationNotAvailable")}</span>
        </div>
      </div>
    </div>
  );
};

export default OnDemandContainer;
