import { useEffect, useState } from "react";
import {
  AllowedModules,
  getValueFromTokenByKey,
  SocTokenKeys,
  TokenKeys,
} from "compass-shared-services";
import { useGetToken, useI18n } from "compass-commons";
import { UserDTO } from "../models/users/UserDTO";
import UserManagerService from "../services/UserManagerService";
import { useGlobalContext } from "../contexts/GlobalContext";

const useCurrentUser = (): UserDTO | null => {
  const { t: translate } = useI18n();
  const { stateService } = useGlobalContext();
  let token = useGetToken();
  if (isStandalone) token = localStorage.getItem("token");
  const [currentUser, setCurrentUser] = useState<UserDTO | null>(null);

  useEffect(() => {
    if (!token) return;

    const currentUserEmail = getValueFromTokenByKey(
      token,
      isDMS ? TokenKeys.Email : SocTokenKeys.Email
    );
    UserManagerService.isUserAllowed(AllowedModules.OPERATION)
      .then((isAllowed) => {
        return isAllowed
          ? UserManagerService.getUserByEmail(currentUserEmail)
          : null;
      })
      .then((user) => {
        setCurrentUser(user);
      })
      .catch(() => {
        stateService.alertSubject.next({
          title: translate(
            "supervisor.assignIncident.failedFetchOnlineOperators"
          ),
        });
      });
  }, [token, translate, stateService]);

  return currentUser;
};

export default useCurrentUser;
