/**
 * ROUTER DASHBOARD HELPERS
 */
// The root path for this configuration microFrontend
/* eslint-disable import/prefer-default-export */

import { isUnity } from "compass-commons";

function getMiddlePath(url: string, first: string, second: string) {
  const regex = new RegExp(`/${first}(.*?)${second}`);
  return regex.exec(url)?.[1] || "/";
}

const url = !isStandalone ? window.location.href : "/monitor/dashboards";
const middlePath = getMiddlePath(url, "monitor", "dashboards");

export const ROOTPATH = (() => {
  if (!isDMS) return "/response/queue";
  if (isUnity) return `/monitor${middlePath}dashboards`;
  return appConfig.COMPASS_DASHBOARDS_PATH || "/ui/dev/live/dashboards";
})();

export const OPERATION_PATH = (() => {
  if (!isDMS) return "response";
  if (isUnity) return `/monitor${middlePath}operation`;
  return appConfig.COMPASS_OPERATION_PATH || "/ui/dev/live/operation";
})();

export const ONDEMAND_INCIDENT_ROUTE = "ondemand-incident";
