import { format } from "date-fns";

/* eslint-disable import/prefer-default-export */
export const convertToBoolean = (value: string): boolean => {
  return value === "true";
};

export const convertSecToMili = (value: number): number =>
  Math.floor(value / 1000);

export const safelyFormatDateAMPM = (timestamp: number) => {
  const date = new Date(timestamp);
  return date ? format(date, "LLL d hh:mm:ss aa") : null;
};
