import React, { useEffect, useState } from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import {
  isUnity,
  useFeatureFlag,
  useI18n,
  withI18n,
  sessionStorageIdentifiers,
  LocalizationNS,
  useGetToken,
} from "compass-commons";
// Components
import { AuthWrapper, Button, Alert, AppTitle, DMSTheme } from "dms-lib";
// Styles
import "./App.module.css";
import "./assets/css/global.css";
import "./styles.scss";
import SupervisorDashboard from "./components/supervisorDashboard/SupervisorDashboard";
import { globalService } from "./services/GlobalService";
import { GlobalProvider } from "./contexts/GlobalContext";
import UserManagerService from "./services/UserManagerService";
// Utils
import { SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG } from "./utils/Constants";
import OnDemandIncidentWrapper from "./components/onDemandIncident/OnDemandIncidentWrapper";
import { ROOTPATH, ONDEMAND_INCIDENT_ROUTE } from "./router/Routes";

const { MFE_HELP_LINK_KEYWORD } = appConfig;

/**
 * Remove when the move to Unity is complete
 */
const Router = isUnity ? HashRouter : BrowserRouter;

const App = (): JSX.Element => {
  const token = (() => {
    const theToken = useGetToken();
    return isStandalone ? localStorage.getItem("token") : theToken;
  })();
  const [isAllowedUser, setIsAllowedUser] = useState<boolean>();
  const { t: translate } = useI18n();
  const { alertSubject } = globalService.stateService;
  const isLoggedInUser = Boolean(
    isStandalone ? localStorage.getItem("token") : token
  );

  const { enabled: showErrorMessagesFeatureFlag } = useFeatureFlag(
    appConfig,
    SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG
  );

  useEffect(() => {
    const mainContextHelpKeyword = sessionStorage.getItem(
      sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD
    );

    if (mainContextHelpKeyword !== MFE_HELP_LINK_KEYWORD) {
      sessionStorage.setItem(
        sessionStorageIdentifiers.MAIN_CONTEXT_HELP_KEYWORD,
        MFE_HELP_LINK_KEYWORD
      );
    }
  }, []);

  useEffect(() => {
    if (!token) return;
    UserManagerService.isUserAllowed().then((value) => {
      setIsAllowedUser(value);
    });
  }, [token]);

  const isStandaloneMode = () => {
    return isStandalone != null && isStandalone;
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  const refreshAction = (
    <Button size="small" color="primary" variant="text" onClick={handleRefresh}>
      Refresh
    </Button>
  );
  useEffect(() => {
    const handleReject = () => {
      if (!showErrorMessagesFeatureFlag) return;
      alertSubject.next({
        title: translate("genericErrorTitle", { ns: LocalizationNS.SHARED }),
        description: translate("genericErrorSubtitle", {
          ns: LocalizationNS.SHARED,
        }),
        action: refreshAction,
      });
    };
    window.addEventListener("unhandledrejection", handleReject);
    return () => {
      window.removeEventListener("unhandledrejection", handleReject);
    };
  }, [showErrorMessagesFeatureFlag]);

  return (
    (isLoggedInUser || isStandaloneMode()) && (
      <React.StrictMode>
        <GlobalProvider value={globalService}>
          {isDMS && (
            <AppTitle translate={translate} localizationNS={LocalizationNS} />
          )}
          <AuthWrapper
            isAuthorized={isAllowedUser || isStandaloneMode()}
            isLoading={isStandaloneMode() ? false : isAllowedUser === undefined}
            unauthorizedTitle={translate("unauthorized", {
              ns: LocalizationNS.SHARED,
            })}
            unauthorizedDescription={translate("unauthorizedContact", {
              ns: LocalizationNS.SHARED,
            })}
          >
            <div className="dashboard-root" data-cr="dashboard-root">
              <div className="dashboard-layout">
                <Router>
                  <Routes>
                    <Route path={ROOTPATH} element={<SupervisorDashboard />} />
                    <Route
                      path={`${ROOTPATH}/${ONDEMAND_INCIDENT_ROUTE}`}
                      element={<OnDemandIncidentWrapper />}
                    />
                  </Routes>
                </Router>
              </div>
            </div>
            <Alert
              alertNotificationSubject={alertSubject}
              autoHideDuration={4000}
            />
          </AuthWrapper>
        </GlobalProvider>
      </React.StrictMode>
    )
  );
};

export default withI18n(App);
