import { isUnity } from "compass-commons";
import { OPERATION_PATH } from "../router/Routes";

const { ENV, NAMESPACE } = appConfig;

const getCookie = (name: string) => {
  const cookie = {};
  document.cookie.split(";").forEach((el) => {
    const [key, ...val] = el.split("=");
    cookie[key.trim()] = val.join("=");
  });
  return cookie[name];
};

export const checkIsTabOpen = (
  appName = `${ENV}/${NAMESPACE}/single-tab-incident-response`,
  localStorageTimeout = 5 * 1000 // 5 seconds
): boolean => {
  try {
    const cookie = getCookie(appName);
    if (!cookie) return false;
    const val = decodeURIComponent(cookie);
    const tabObj = val ? JSON.parse(val) : null;
    return (
      tabObj && tabObj.timestamp >= new Date().getTime() - localStorageTimeout
    );
  } catch (e) {
    return false;
  }
};

export const openOperationTabIfClosed = (): void => {
  if (!isDMS || checkIsTabOpen()) return;
  const baseUrl = `${window.location.origin}${isUnity ? "/dms/#" : ""}`;
  let oPath = OPERATION_PATH;
  if (!OPERATION_PATH.startsWith("/")) {
    oPath = `/${OPERATION_PATH}`;
  }
  window.open(`${baseUrl}${oPath}`, "_blank");
};
