import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useI18n } from "compass-commons";
import { AutoComplete, OptionType, ResourceIcon } from "dms-lib";
import useSWRInfinite from "swr/infinite";
import { debounce } from "@mui/material/utils";
import OIMService from "../../../../../services/OIMService";
import { DEFAULT_INCIDENT_QUEUES_PAGE_SIZE } from "../../../../../hooks/useActivations";
import RequestKey from "../../../../../utils/Requests";
import { useGlobalContext } from "../../../../../contexts/GlobalContext";
import { IncidentQueueDTO } from "../../../../../models/incidentQueues/IncidentQueueDTO";
import "./selectIncidentQueue.scss";
import { DEFAULT_DEBOUNCE_TIMEOUT } from "../../../../../utils/Constants";

interface IncidentQueueOption extends OptionType {
  name: string;
  icon: string;
}

const SelectIncidentQueue = (): JSX.Element => {
  const [showIcon, setShowIcon] = useState(true);
  const [previousQueryValue, setPreviousQueryValue] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [selectedIncidentQueue, setSelectedIncidentQueue] =
    useState<IncidentQueueDTO>(null);

  const { NAMESPACE } = appConfig;
  const { stateService } = useGlobalContext();
  const { selectedIncidentQueueSubject, alertSubject } = stateService;
  const { t: translate } = useI18n();
  const { data, error, isLoading, size, setSize } = useSWRInfinite(
    (pageIndex, prevPageData) => {
      if (prevPageData && !prevPageData.length) return null;
      return {
        key: RequestKey.IncidentQueues,
        pageIndex,
        pageSize: DEFAULT_INCIDENT_QUEUES_PAGE_SIZE,
        queryValue,
      };
    },
    OIMService.getIncidentQueues
  );
  const fetchNextData = useCallback(
    (nextPageIndex) => {
      if (nextPageIndex + 1 === size && previousQueryValue === queryValue)
        return;
      if (!isLoading) setSize(size + 1).then();
    },
    [setSize, size, isLoading, previousQueryValue, queryValue]
  );

  const incidentQueues = useMemo(() => {
    return data?.map((d) => d).flat() ?? [];
  }, [data]);
  const isLastPage =
    data?.[size - 1]?.length < DEFAULT_INCIDENT_QUEUES_PAGE_SIZE;

  useEffect(() => {
    if (!selectedIncidentQueue) return;
    selectedIncidentQueueSubject.next({
      id: selectedIncidentQueue?.id,
    });
  }, [selectedIncidentQueue, selectedIncidentQueueSubject]);

  useEffect(() => {
    if (error) {
      alertSubject.next({
        title: translate("supervisor.incidentQueues.failedFetchIncidentQueues"),
      });
      selectedIncidentQueueSubject.next({ id: null });
    }
  }, [error, alertSubject, translate]);

  useEffect(() => {
    if (selectedIncidentQueue || !data?.length) return;

    const flatData = data.flat();
    if (flatData.length === 0) {
      alertSubject.next({
        title: translate("supervisor.incidentQueues.noIncidentQueuesError"),
      });
      selectedIncidentQueueSubject.next({ id: null });
      return;
    }

    const storedId = localStorage.getItem(`${NAMESPACE}_selectedIncidentQueue`);
    const incidentQueue =
      (storedId && flatData.find((iq) => iq.id === storedId)) || flatData[0];
    setSelectedIncidentQueue(incidentQueue);
    localStorage.setItem(
      `${NAMESPACE}_selectedIncidentQueue`,
      incidentQueue.id
    );
  }, [data, alertSubject, translate, selectedIncidentQueue]);

  const onChangeCallback = (incidentQueue: IncidentQueueDTO) => {
    setSelectedIncidentQueue(incidentQueue);
    localStorage.setItem(
      `${NAMESPACE}_selectedIncidentQueue`,
      incidentQueue.id
    );
  };

  const onInputChange = (_event, value, reason) => {
    const debounceHandler = debounce((v) => {
      setPreviousQueryValue(queryValue);
      setQueryValue(v);
    }, DEFAULT_DEBOUNCE_TIMEOUT);
    if (reason === "input") {
      debounceHandler(value);
      setShowIcon(false);
    } else if (reason === "reset") {
      setShowIcon(true);
      setQueryValue("");
    }
  };
  return (
    <div
      className="dashboards__table-select-incident-queue"
      data-cr="dashboards-table-select-incident-queue"
    >
      <AutoComplete<IncidentQueueOption>
        dataCr="incident-queue-auto-complete"
        getOptionLabel={(option) => option?.name || ""}
        loading={isLoading}
        disableLinearLoad
        loadingText={translate("loadingContent", { ns: "Shared" })}
        placeholder={translate("supervisor.incidentQueues.selectIncidentQueue")}
        options={incidentQueues}
        disableClearable
        onChangeCallback={onChangeCallback}
        value={selectedIncidentQueue || { id: "", name: "", icon: "" }}
        onInputChange={onInputChange}
        infiniteScroll={{
          pageLength: DEFAULT_INCIDENT_QUEUES_PAGE_SIZE,
          hasMore: !isLastPage,
          fetchNext: fetchNextData,
          isLoading,
        }}
        IconComponent={({ value, className, dataCr: dtCr, isSelected }) =>
          (showIcon || isSelected) && value?.icon ? (
            <ResourceIcon
              data-cr={dtCr}
              className={className}
              src={value?.icon}
            />
          ) : (
            <></>
          )
        }
      />
    </div>
  );
};

export default SelectIncidentQueue;
