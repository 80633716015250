import { Spinner } from "dms-lib";
import React from "react";

const SpinnerDMS = (): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Spinner size="large" />
    </div>
  );
};

export default SpinnerDMS;
