import { HttpClient } from "compass-shared-services";
import { getUrl, normalizeAPIObject, incidentPriority } from "compass-commons";
import { PagedOperationIncidentListDTO } from "../models/supervisorDashboard/PagedOperationIncidentListDTO";
import { ClearIncidentRequestDTO } from "../models/supervisorDashboard/ClearIncidentRequestDTO";
import { IncidentAdditionalActivationDTO } from "../models/supervisorDashboard/IncidentAdditionalActivation";
import { IncidentAdditionalActivationResponseDTO } from "../models/supervisorDashboard/IncidentAdditionalActivationResponseDTO";
import {
  OnDemandIncidentDTO,
  OnDemandIncidentResponseDTO,
} from "../models/onDemandIncident/onDemandIncident";
import { OnDemandIncidentFormSubmit } from "../models/onDemandIncident/onDemandIncidentForm";
import {
  QueueIncidentPriority,
  IncidentsQueueCountDTO,
  IncidentsQueueCountResponseDTO,
} from "../models/incidentsQueue/IncidentsQueueDTO";
import {
  ListIncidentQueuesRequest,
  ListIncidentQueuesResponse,
} from "../models/incidentQueues/ListIncidentQueueDTO";
import { FetchIncidentsRequestDTO } from "../models/supervisorDashboard/FetchIncidentsRequestDTO";
import { IncidentAssignFilterType } from "../models/incidentsQueue/IncidentsQueueQueries";
import { INCIDENT_QUEUE_PAGE_SIZE } from "../utils/Constants";

const { OPERATION_INCIDENT_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  GET_INCIDENT_LIST: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents`,
  CLEAR_INCIDENT: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/execution/(executionId)/clear-unassigned`,
  CLEAR_ALL_INCIDENTS: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/clear-all`,
  ASSIGN_INCIDENT_TO_USER: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/assign`,
  // ACTIVATION
  GET_ADDITIONAL_ACTIVATIONS: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/additional-activations`,
  // ON DEMAND
  CREATE_ONDEMAND_INCIDENT: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents`,
  UNASSIGN_INCIDENT: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/unassign`,
  REASSIGN_INCIDENT: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/reassign`,
  FILTERED_INCIDENT_LIST: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/filtered-incidents`,
  ACTIVE_INCIDENTS_COUNT: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/active-incidents-count-by?criteria=(sortBy)`,
  // INCIDENT QUEUES
  INCIDENT_QUEUES: `${OPERATION_INCIDENT_MANAGER_PATH}/incident-queue`,
};

interface ClearAllRequestDTO {
  description: string;
}

interface ClearRequestDTO {
  description: string;
}

/**
 * Operator Incident Manager Service where backend service is called for
 * * incident Lists
 */
export default class OIMService {
  // We need to check the pageIndex and only abort if it is the same
  // Otherwise it can be a fast scroller
  static abortControllerData: {
    pageIndex: number;
    controller: AbortController;
  };

  static async getIncidentList(
    fetchIncidentsRequest: FetchIncidentsRequestDTO
  ): Promise<PagedOperationIncidentListDTO> {
    const { pageIndex, pageSize, incidentQueueId } = fetchIncidentsRequest;
    let urlPath = `${URL_PATHS.GET_INCIDENT_LIST}?pageNumber=${pageIndex}&pageSize=${pageSize}`;
    if (incidentQueueId) {
      urlPath = urlPath.concat(`&incidentQueueId=${incidentQueueId}`);
    }
    return httpClient
      .get<PagedOperationIncidentListDTO>({ url: urlPath })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async clearIncident(
    clearIncidentRequest: ClearIncidentRequestDTO
  ): Promise<string> {
    const uri = {
      incidentId: clearIncidentRequest.incidentId,
      executionId: clearIncidentRequest.executionId,
    };
    const urlPath = getUrl(URL_PATHS.CLEAR_INCIDENT, uri);
    const body: ClearRequestDTO = {
      description: clearIncidentRequest.comment,
    };
    return httpClient
      .delete<string>({ url: urlPath, config: { data: body } })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async clearAllIncidents(
    clearIncidentRequest: ClearIncidentRequestDTO
  ): Promise<number> {
    const body: ClearAllRequestDTO = {
      description: clearIncidentRequest.comment,
    };
    let urlPath = URL_PATHS.CLEAR_ALL_INCIDENTS;
    if (clearIncidentRequest?.incidentQueueId) {
      urlPath = urlPath.concat(
        `?incidentQueueId=${clearIncidentRequest.incidentQueueId}`
      );
    }
    return httpClient
      .postReturningAllResponse<any>({
        url: urlPath,
        payload: body,
      })
      .then((response) => {
        return response.status;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async assignIncidentToUser(
    incidentId: string,
    userId: string
  ): Promise<string> {
    const uri = {
      incidentId,
    };
    const urlPath = getUrl(URL_PATHS.ASSIGN_INCIDENT_TO_USER, uri);
    const body = {
      userId,
    };
    return httpClient
      .post<any>({ url: urlPath, payload: body })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(JSON.stringify(error));
      });
  }

  // ADDITIONAL ACTIVATIONS

  static async getAdditionalActivations(
    incidentId: string,
    pageParameters?: {
      pageIndex: number;
      pageSize: number;
    }
  ): Promise<IncidentAdditionalActivationDTO[]> {
    if (this.abortControllerData?.pageIndex === pageParameters?.pageIndex)
      this.abortControllerData.controller.abort();
    this.abortControllerData = {
      pageIndex: pageParameters?.pageIndex || 0,
      controller: new AbortController(),
    };
    const uri = {
      incidentId,
    };
    const urlPath = getUrl(URL_PATHS.GET_ADDITIONAL_ACTIVATIONS, uri);
    return httpClient
      .get<IncidentAdditionalActivationResponseDTO>({
        url: urlPath,
        config: {
          params: {
            pageIndex: pageParameters?.pageIndex,
            pageSize: pageParameters?.pageSize,
          },
          signal: this.abortControllerData.controller.signal,
        },
      })
      .then((response) => {
        return normalizeAPIObject(response.additionalActivations);
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  static async createOnDemandIncident(
    formData: OnDemandIncidentFormSubmit
  ): Promise<OnDemandIncidentDTO> {
    return httpClient
      .post<OnDemandIncidentResponseDTO>({
        url: URL_PATHS.CREATE_ONDEMAND_INCIDENT,
        payload: JSON.stringify(formData),
      })
      .then((response) => {
        return normalizeAPIObject(response.onDemandIncident);
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getFilteredIncidents(
    priority: QueueIncidentPriority
  ): Promise<PagedOperationIncidentListDTO> {
    const urlPath = `${URL_PATHS.FILTERED_INCIDENT_LIST}?pageNumber=0&pageSize=${INCIDENT_QUEUE_PAGE_SIZE}`;
    const body: any = {
      priorities: [priority],
      orderDirection: "DESC",
      orderBy: "incidentTimestamp",
    };
    return httpClient
      .postReturningAllResponse<any>({
        url: urlPath,
        payload: body,
      })
      .then((response) => {
        if (response.status === 204) {
          response.data = { operationIncidentList: [], totalRecords: 0 };
        }
        return response.data as PagedOperationIncidentListDTO;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getActiveIncidentsCount(
    filterMode: IncidentAssignFilterType
  ): Promise<IncidentsQueueCountDTO[]> {
    const body = {};
    let url = getUrl(URL_PATHS.ACTIVE_INCIDENTS_COUNT, {
      sortBy: "priority",
    });
    if (filterMode === "ONLY_UNASSIGNED") {
      url += "&isAssigned=false";
    }
    return httpClient
      .get<IncidentsQueueCountResponseDTO>({
        url,
        payload: body,
      })
      .then((response) => {
        return response.countList;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async unAssignIncident(
    incidentId: string,
    userId: string
  ): Promise<string> {
    const uri = {
      incidentId,
    };
    const urlPath = getUrl(URL_PATHS.UNASSIGN_INCIDENT, uri);
    const body = {
      userId,
      forceUnassign: false,
    };
    return httpClient
      .post<any>({ url: urlPath, payload: body })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(JSON.stringify(error));
      });
  }

  static async reAssignIncidentToUser(
    incidentId: string,
    oldUserId: string,
    newUserId: string
  ): Promise<string> {
    const uri = {
      incidentId,
    };
    const urlPath = getUrl(URL_PATHS.REASSIGN_INCIDENT, uri);
    const body = {
      previousUserId: oldUserId,
      newUserId,
    };
    return httpClient
      .post<any>({ url: urlPath, payload: body })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(JSON.stringify(error));
      });
  }

  static async getIncidentQueues(
    incidentQueuesRequest: ListIncidentQueuesRequest
  ): Promise<ListIncidentQueuesResponse["incidentQueues"]> {
    const params = {
      pageIndex: incidentQueuesRequest?.pageIndex,
      pageSize: incidentQueuesRequest?.pageSize,
      queryValue: incidentQueuesRequest?.queryValue,
    };
    if (incidentQueuesRequest?.queryValue === "") {
      delete params.queryValue;
    }

    return httpClient
      .get<ListIncidentQueuesResponse>({
        url: URL_PATHS.INCIDENT_QUEUES,
        config: { params },
      })
      .then((response) => {
        return response?.incidentQueues;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
