import { useEffect, useState } from "react";
import { useFeatureFlag } from "compass-commons";
import {
  INCIDENT_QUEUES_FEATURE_FLAG,
  ON_DEMAND_INCIDENT_FEATURE_FLAG,
} from "../utils/Constants";
import { useGlobalContext } from "../contexts/GlobalContext";

const useTableActionsFeatureFlag = () => {
  const { stateService } = useGlobalContext();
  const { selectedIncidentQueueSubject } = stateService;
  const [loadingFeatureFlag, setLoadingFeatureFlag] = useState(true);
  const [onDemandFeatureFlag, setOnDemandFeatureFlag] = useState(null);
  const [iQueuesFeatureFlag, setIQueuesFeatureFlag] = useState(null);
  const { isFeatureEnabled } = useFeatureFlag(appConfig, null, null, {
    skipAutoRequest: true,
  });

  useEffect(() => {
    isFeatureEnabled(INCIDENT_QUEUES_FEATURE_FLAG)
      .then((enabled) => {
        setIQueuesFeatureFlag(enabled);
      })
      .catch(() => setIQueuesFeatureFlag(false));
    isFeatureEnabled(ON_DEMAND_INCIDENT_FEATURE_FLAG)
      .then((enabled) => {
        setOnDemandFeatureFlag(enabled);
      })
      .catch(() => setOnDemandFeatureFlag(false));
  }, [isFeatureEnabled]);

  useEffect(() => {
    if (onDemandFeatureFlag !== null && iQueuesFeatureFlag !== null) {
      setLoadingFeatureFlag(false);
      if (!iQueuesFeatureFlag) selectedIncidentQueueSubject.next({ id: null });
    }
  }, [onDemandFeatureFlag, iQueuesFeatureFlag]);

  return { loadingFeatureFlag, onDemandFeatureFlag, iQueuesFeatureFlag };
};

export default useTableActionsFeatureFlag;
