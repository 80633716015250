import { useEffect, useState } from "react";

interface PageState {
  pageIndex: number;
  pageSize: number;
  totalPages: number;
}

const usePagination = (
  totalPages: number,
  pageSize = 10,
  pageIndex = 0
): {
  pageState: PageState;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (newPageIndex: number) => void;
} => {
  const [pageState, setPageState] = useState<PageState>({
    pageIndex,
    pageSize,
    totalPages,
  });

  useEffect(() => {
    setPageState((prevState) => ({
      ...prevState,
      totalPages,
      pageSize,
      pageIndex,
    }));
  }, [totalPages, pageSize, pageIndex]);

  const nextPage = () =>
    setPageState((prevState) => ({
      ...prevState,
      pageIndex: prevState.pageIndex + 1,
    }));

  const previousPage = () =>
    setPageState((prevState) => ({
      ...prevState,
      pageIndex: prevState.pageIndex - 1,
    }));

  const gotoPage = (newPageIndex: number) => {
    setPageState((prevState) => ({
      ...prevState,
      pageIndex: newPageIndex,
    }));
  };

  return { pageState, nextPage, gotoPage, previousPage };
};

// eslint-disable-next-line import/prefer-default-export
export { usePagination };
